import "./src/base/styles/main.css";

import React from "react";
import { MatchMediaProvider } from "@/base/context";
import "slick-carousel/slick/slick.css";

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const isScrollingToSection = location.hash.trim() !== "";

  if (isScrollingToSection) {
    return true;
  }

  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};

export const wrapRootElement = ({ element }) => {
  return <MatchMediaProvider>{element}</MatchMediaProvider>;
};
