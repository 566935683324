import React, { createContext, ReactNode, useContext } from "react";

import { useMatchMedia } from "@/base/hooks";

const LocalStateContext = createContext({
  isTablet: false,
  isDesktop: false,
  isNotDesktop: false,
});

const LocalStateProvider = LocalStateContext.Provider;

interface Props {
  children: ReactNode;
}

function MatchMediaProvider({ children }: Props) {
  const isTablet = useMatchMedia("(min-width: 1024px)");
  const isDesktop = useMatchMedia("(min-width: 1280px)");
  const isNotDesktop = useMatchMedia("(max-width: 1280px)");

  return (
    <LocalStateProvider value={{ isTablet, isDesktop, isNotDesktop }}>
      {children}
    </LocalStateProvider>
  );
}

function useMatchMediaContext() {
  const all = useContext(LocalStateContext);
  return all;
}

export { MatchMediaProvider, useMatchMediaContext };
